@import url(https://fonts.googleapis.com/css?family=Lato:300,400,500,700);
body {
  margin: 0;
  height: 100vh !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Lato', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  /* overflow: hidden; */
}

html { margin: 0; height: 100vh !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*! Flickity v1.1.2
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%}.flickity-slider{position:absolute;width:100%;height:100%}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:grabbing}.flickity-prev-next-button{position:absolute;top:50%;width:44px;height:44px;border:none;border-radius:50%;background:#fff;background:hsla(0,0%,100%,.75);cursor:pointer;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.flickity-prev-next-button:hover{background:#fff}.flickity-prev-next-button:focus{outline:0;box-shadow:0 0 0 5px #09F}.flickity-prev-next-button:active{filter:alpha(opacity=60);opacity:.6}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button:disabled{filter:alpha(opacity=30);opacity:.3;cursor:auto}.flickity-prev-next-button svg{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-prev-next-button .arrow{fill:#333}.flickity-prev-next-button.no-svg{color:#333;font-size:26px}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;filter:alpha(opacity=25);opacity:.25;cursor:pointer}.flickity-page-dots .dot.is-selected{filter:alpha(opacity=100);opacity:1}

span {
  font-family: "Lato", "Helvetica Neue" !important;
}

p {
  font-family: "Lato", "Helvetica Neue" !important;
}

.iFc {
  font-family: "Lato", "Helvetica Neue" !important;
}

.ZHw {
  border-radius: 0px !important;
}

.Jrn {
  background: #CD1E3B !important;
}

.rTt {
  background: #51D698!important;
}

.RCK {
  border-radius: 5px !important;
}

.prG {
  background: rgba(0, 0, 0, 0.8) !important;
}

.MuiTableCell-root {
  padding: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.MuiTableRow-root {
  height: 35px !important;
}

/* .qJc {
  background: #000 !important;
} */

/* .makeStyles-drawerClose-107 {
  width: 40 !important;
} */
